<template>
  <b-form role="form" class="mx-4 mx-lg-0">
    <base-input
      class="text-left"
      :name="$t('returnLabelForm.step1.orderNumber')"
      :label="$t('returnLabelForm.step1.orderNumber')"
      :rules="{ required: true }"
          required
      v-model="returnLabel.orderNumber"
    >
    </base-input>
    <b-row class="text-left">
      <b-col lg="6">
        <base-input
          :name="$t('returnLabelForm.step1.firstName')"
          :label="$t('returnLabelForm.step1.firstName')"
          :rules="{ required: true }"
          required
          v-model="returnLabel.firstName"
        >
        </base-input>
      </b-col>
      <b-col lg="6">
        <base-input
          :name="$t('returnLabelForm.step1.lastName')"
          :label="$t('returnLabelForm.step1.lastName')"
          :rules="{ required: true }"
          required
          v-model="returnLabel.lastName"
        >
        </base-input>
      </b-col>
    </b-row>
    <b-row class="text-left">
      <b-col lg="8">
        <base-input
          :name="$t('returnLabelForm.step1.street')"
          :label="$t('returnLabelForm.step1.street')"
          :rules="{ required: true }"
          required
          v-model="returnLabel.street"
        >
        </base-input>
      </b-col>
      <b-col lg="4">
        <base-input
          :name="$t('returnLabelForm.step1.number')"
          :label="$t('returnLabelForm.step1.number')"
          :rules="{ required: true }"
          required
          v-model="returnLabel.streetNumber"
        >
        </base-input>
      </b-col>
    </b-row>
    <b-row class="text-left">
      <b-col lg="3">
        <base-input
          :name="$t('returnLabelForm.step1.zip')"
          :label="$t('returnLabelForm.step1.zip')"
          :rules="zipCodeRules"
          required
          v-model="returnLabel.zipCode"
        >
        </base-input>
      </b-col>
      <b-col lg="9">
        <base-input
          :name="$t('returnLabelForm.step1.city')"
          :label="$t('returnLabelForm.step1.city')"
          :rules="{ required: true }"
          required
          v-model="returnLabel.city"
        >
        </base-input>
      </b-col>
    </b-row>

    <base-input
      class="text-left"
      :name="$t('returnLabelForm.step1.country')"
      :label="$t('returnLabelForm.step1.country')"
      :rules="{ required: true }"
      required
      v-slot="{ errorMessage }"
    >
      <b-form-select
        v-model="returnLabel.countryCode"
        :options="countries"
        value-field="countryCode"
        text-field="countryName"
        :class="{ 'is-invalid': !!errorMessage }"
      ></b-form-select>
    </base-input>
  </b-form>
</template>
<script>
export default {
  props: ["valid"],
  watch: {
    valid: {
      immediate: true,
      handler() {
        this.$emit("change", this.returnLabel, this.valid);
      },
    },
  },
  computed: {
    zipCodeRules() {
      const currentCountry = this.countries.find(
        (el) => el.countryCode === this.returnLabel.countryCode
      );
      if (currentCountry) {
        return {
          required: true,
          regex: new RegExp(currentCountry.postalCodeRegex),
        };
      } else {
        return { required: true };
      }
    },
  },
  async mounted() {
    const countries = JSON.parse(localStorage.getItem('countries'));
    if (Array.isArray(countries) && countries.length) {
      this.countries = countries;
      this.returnLabel.countryCode = this.countries[0].countryCode;
    } else {
      this.$notify({
        message: this.$t("api.errors.countries"),
        type: "danger",
        showClose: false,
      });
    }
    const deactivatedButton = document.querySelector('.stepper-box .bottom .stepper-button');
    if(deactivatedButton) {
      deactivatedButton.setAttribute('style', 'background-color: #3383c8 !important;');
    }
  },
  data() {
    return {
      returnLabel: {
        orderNumber: "",
        firstName: "",
        lastName: "",
        street: "",
        streetNumber: "",
        zipCode: "",
        city: "",
        countryCode: "",
      },
      countries: [],
    };
  },
};
</script>
