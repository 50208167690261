<template>
  <b-card-body class="px-lg-5">
    <div class="mb-4">
      <small>
        {{ $t('returnLabelForm.step1.enterSenderData') }}
      </small>
    </div>
    <validation-observer v-slot="{ valid }" ref="formValidator">
      <return-label-form :valid="valid" @change="changeValid" />
    </validation-observer>
  </b-card-body>
</template>
<script>
import ReturnLabelForm from "./ReturnLabelForm.vue";

export default {
  props: ["clickedNext", "currentStep"],
  components: {
    ReturnLabelForm,
  },
  data() {
    return {
      formData: null
    }
  },
  methods: {
    changeValid(formData, isValid) {
      this.formData = formData;
      this.$emit("can-continue", { value: isValid });
      this.currentStep.formData = formData;
    },
  },
  watch: {
    clickedNext(val) {
      if (val === true) {
        this.$refs.formValidator.validate();
      }
    },
    currentStep(val) {
      if(!val.formData) {
        this.currentStep.formData = this.formData;
      }
    }
  },
};
</script>
