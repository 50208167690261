<template>
  <b-card-group deck class="mx-4" footer-tag="footer">
    <b-card title="PDF">
      <template #footer>
        <button class="btn btn-primary customBlueBG" @click="download">{{ $t('returnLabelForm.step2.pdf') }}</button>
      </template>
    </b-card>

    <b-card :title="$t('returnLabelForm.step2.textForParcelShop')" footer-tag="footer">
      <template #footer>
        <a class="btn btn-primary customBlueBG" href="https://www.myhermes.at/privatkunden/sendung-aufgeben/hermes-paketshop-finden/" role="button" target="_blank">{{ $t('returnLabelForm.step2.parcelShopButton') }}</a>
      </template>
    </b-card>
  </b-card-group>
</template>
<script>
export default {
  props: ['currentStep'],
  mounted() {
    document.querySelector('.card').scrollIntoView();
  },
  methods: {
    download() {
      function dataURItoBlob(dataURI) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
          int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'application/pdf'});
        return blob;
      }

      if(this.currentStep && this.currentStep.name) {
        const blob = dataURItoBlob(this.currentStep.name);
        const url = URL.createObjectURL(blob);
  
        window.open(url, '_blank');
      }
    }
  }
}
</script>