<template>
  <div class="header bg-gradient-success py-3 py-lg-4 pt-lg-5 fullHeight">
    <b-container>
      <div class="header-body text-center mb-3">
        <b-row class="justify-content-center">
          <b-col class="px-5">
            <h1 class="text-white">
              {{ $t("returnLabelForm.returnLabel") }}
            </h1>
          </b-col>
        </b-row>
        <b-row class="justify-content-center returnLabelForm">
          <b-col lg="8" md="10">
            <b-overlay :show="showOverlay" rounded="sm">
              <b-card no-body class="border-0 mb-0">
                <b-card-header class="bg-transparent pb-3">
                  <b-row v-if="withImg">
                    <b-col lg="3">
                      <img
                        class="brandLogo"
                        :alt="companyName"
                        :src="
                          require('@/assets/img/brand/' + companyLogoFileName)
                        "
                      />
                    </b-col>
                    <b-col lg="6">
                      <h3 class="text-lg-left mt-4">
                        {{ returnToLabel }}
                      </h3>
                    </b-col>
                    <b-col lg="3">
                      <locale-switcher />
                    </b-col>
                  </b-row>
                  <template v-else>
                    <b-row class="d-flex justify-content-end">
                      <locale-switcher class="text-right" />
                    </b-row>
                    <b-row class="d-flex justify-content-center">
                      <h3>
                        {{ returnToLabel }}
                      </h3>
                    </b-row>
                  </template>
                </b-card-header>
                <horizontal-stepper
                  :steps="steps"
                  @completed-step="completeStep"
                  @active-step="isStepActive"
                  @clicking-back="handleBackClick"
                  :class="{ 
                    'stepper-second-step': isStepSecond, 
                    'stepper-first-step': !isStepSecond 
                    }"
                  ref="stepper"
                >
                </horizontal-stepper>
              </b-card>
            </b-overlay>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>
<script>
import HorizontalStepper from "vue-stepper";

import stepOne from "../ReturnLabel/ReturnLabelCard.vue";
import stepTwo from "../ReturnLabel/GetPDFLabel.vue";

import { returnLabel } from "@/api/returnlabel";
import LocaleSwitcher from "@/components/LocaleSwitcher.vue";
import { checkCompany } from "@/api/companies";

export default {
  components: {
    HorizontalStepper,
    LocaleSwitcher,
  },
  data() {
    return {
      steps: [
        {
          name: "first",
          icon: "looks_one",
          title: this.$t("returnLabelForm.step1.label"),
          component: stepOne,
          completed: false,
        },
        {
          name: "second",
          icon: "looks_two",
          title: this.$t("returnLabelForm.step2.label"),
          component: stepTwo,
          completed: false,
        },
      ],
      returnToLabel: '',
      isStepSecond: false,
      isStepReturned: false,
      showOverlay: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    checkCompany(to.params.companyName).then(data => {
       next(vm => {
        vm.fullCompanyName = data.data.companyName;
        localStorage.setItem('countries', JSON.stringify(data.data.countries));
      });
    })
    .catch(() => {
      next('/')
    })
  },
  mounted() {
    this.changeHeader();
  },
  computed: {
    companyName() {
      return this.$route.params.companyName;
    },
    companyLogoFileName() {
      return (
        this.$route.params.companyLogoFileName || this.companyName + ".png"
      );
    },
    withImg() {
      return this.$route.params.withImg;
    },
  },
  watch: {
    "$i18n.locale": function () {
      const stepperBox = this.$refs.stepper.$el;
      stepperBox.querySelector(".bottom .stepper-button.next span").innerText =
        this.$t("returnLabelForm.step1.nextButton");
      const steps = stepperBox.querySelectorAll(".top .step");
      steps[0].querySelector(".step-title h4").innerText = this.$t(
        "returnLabelForm.step1.label"
      );
      steps[1].querySelector(".step-title h4").innerText = this.$t(
        "returnLabelForm.step2.label"
      );
      this.changeHeader();
    },
  },
  methods: {
    async completeStep(payload) {
      if (this.$refs.stepper && !this.isStepReturned) {
        this.$refs.stepper.backStep();
        this.isStepReturned = true;
        this.showOverlay = true;

        await returnLabel({
          companyPageName: this.companyName,
          ...payload.formData,
        })
          .then((resp) => {
            this.steps[1].name = resp.data.labelBase64;
            this.$refs.stepper.activateStep(1);
            this.showOverlay = false;
          })
          .catch(() => {
            this.$notify({
              message: this.$t("api.errors.internalError"),
              type: "danger",
              showClose: false,
            });
            this.showOverlay = false;
          });
      }
      this.steps.forEach((step) => {
        if (step.name === payload.name) {
          step.completed = true;
        }
      });
    },
    changeHeader() {
      const to = this.fullCompanyName !== "" 
          ? " " + this.$t("returnLabelForm.to") + " " + this.fullCompanyName 
          : ""
      
      this.returnToLabel = this.$t("returnLabelForm.return") + to;
    },
    isStepActive(payload) {
      this.isStepSecond = false;
      this.steps.forEach((step) => {
        if (step.name === payload.name) {
          if (step.completed === true) {
            step.completed = false;
          }
        }
        if (step.name === "first" && step.completed) {
          this.isStepSecond = true;
        }
      });
    },
    handleBackClick() {
      if(this.$refs.stepper) {
        this.$refs.stepper.proceed({ value: true });
        this.isStepReturned = false;
      }
    }
  },
};
</script>
